import React from 'react';
import useForm from '../utils/useForm';
import useQuote from '../utils/useQuote';

export default function ContactForm() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  const { values, updateValue } = useForm({
    name: '',
    email: '',
    phone: '',
    building: '',
    messageText: '',
    mapleSyrup: '',
  });
  const { error, loading, message, submitForm } = useQuote({ values });

  if (message) {
    return <p>{message}</p>;
  }
  return (
    <form
      action="#"
      method="POST"
      onSubmit={submitForm}
      className="grid grid-cols-2 gap-y-6"
    >
      <div className="col-span-2">
        <label htmlFor="name" className="form-label">
              Name
            </label>
        <div className="mt-1">
          <input
            type="text"
            name="name"
            id="name"
            placeholder=""
            value={values.name}
            onChange={updateValue}
            autoComplete="given-name"
            className={classNames(
              error
                ? 'border-2 border-error ring-error'
                : 'border-2 border-primary',
              'block w-full rounded-md  px-5  py-3 shadow-sm sm:text-sm'
            )}
          />
        </div>
      </div>
      <div className="col-span-2 sm:col-span-1 sm:pr-5">
        <div className="flex justify-between" />
        <label htmlFor="phone" className="form-label">
              Phone
            </label>
        <div className="mt-1">
          <input
            type="text"
            name="phone"
            id="phone"
            value={values.phone}
            placeholder=""
            onChange={updateValue}
            autoComplete="tel"
            aria-describedby="phone-description"
            className="block w-full rounded-md  border-2 border-primary px-5 py-3 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
          />
        </div>
      </div>

      <div className="col-span-2 sm:col-span-1">
        <label htmlFor="email" className="form-label">
              Email
            </label>
        <div className="mt-1">
          <input
            id="email"
            name="email"
            type="email"
            placeholder=""
            value={values.email}
            onChange={updateValue}
            autoComplete="email"
            className={classNames(
              error
                ? 'border-2 border-error ring-error'
                : 'border-2 border-primary',
              'block w-full  rounded-md  px-5 py-3 shadow-sm sm:text-sm'
            )}
          />
          <input
            id="mapleSyrup"
            name="mapleSyrup"
            type="text"
            value={values.mapleSyrup}
            onChange={updateValue}
            className="hidden"
          />
        </div>
      </div>
      <div className="col-span-2">
        <div className="flex justify-between" />
        <label htmlFor="service-type" className="form-label">
              Building Type
            </label>
        <div className="mt-1">
            <label htmlFor="building" className="sr-only">
                  Buidling Type
                </label>
           <select
                  id="building"
                  name="building"
                  placeholder=''
                  value={values.building}
                  onChange={updateValue}
                    className="block w-full rounded-md  border-2 border-primary px-5 py-3 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                >
                <option value="" >Select a building type</option>
                 <option value="ICF Building">ICF Building</option>
                 <option value="Custom Build">Custom Build</option>
                <option value="Commercial/Agriculture">Commercial/Agriculture</option>
                <option value="Pole Barn">Pole Barn</option>
                </select>
        </div>
      </div>
      <div className="col-span-2">
        <div className="mt-1">
          <textarea
            id="messageText"
            name="messageText"
            value={values.messageText}
            onChange={updateValue}
            placeholder="Type your message here"
            aria-describedby="how-can-we-help-description"
            rows={4}
            className="block w-full  rounded-md border border-primary px-5 py-3 shadow-sm focus:border-secondary  focus:ring-secondary sm:text-lg"
          />
        </div>

        {error ? (
          <div className="mt-10 border-2 border-dotted border-error  bg-gray-50 p-3 text-center text-error">
            <p>Error: {error} </p>
          </div>
        ) : (
          ''
        )}
      </div>

      <div className="text-left col-span-2">
        <button
          type="submit"
          disabled={loading}
          className="mt-10 inline-flex justify-center border-2   border-secondary bg-primary py-4 px-4 text-lg font-semibold uppercase text-white  shadow-sm  rounded-3xl hover:bg-secondary hover:text-white  hover:border-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
        >
          {loading ? 'Sending' : 'Send Request'}
        </button>
      </div>
    </form>
  );
}
