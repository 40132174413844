import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Contact from '../components/contact';

import { graphql } from 'gatsby';
import Gallery from '../components/Gallery';
import Colophon from '../components/colophon';
import FeatureSectionContentRight from '../components/FeatureSectionContentRight';
import FeatureSectionContentLeft from '../components/FeatureSectionContentLeft';
import FourColumns from '../components/FourColumns';

import HeroSection from '../components/heroSection';

function Index({data}) {
  const { buildings } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Seo title="ICF Buildings, Pole Barns and Detached Structures" />

      <HeroSection/>
      <FeatureSectionContentRight/>
      <FeatureSectionContentLeft />
      <FourColumns/>

      <div className='bg-light'>
      <div className='full-section max-w-7xl mx-auto'>
        <div className='text-center flex flex-col gap-y-5 '>

        <h2 className='text-gray-900'>Check out our work!</h2>
        </div>

        <Gallery buildings={buildings}/>
      </div>
      </div>
      <Contact />
      <Colophon/>
    </Layout>
  );
}

export default Index;

export const query = graphql`
  {
    markdownRemark {
      frontmatter {
        buildings {

          icf {
            title

            src {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          commercial {
            title
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          polebarns {
            title
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          custombuilds {
            title
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
          }

        }
      }
    }
  }
`;
