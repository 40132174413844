import {
  EnvelopeIcon,
  PhoneIcon,
  ClockIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import ContactForm from './form';

export default function Contact() {
  return (
    <div className="relative bg-gray-900" id="contact">

      <div className="absolute inset-0 overflow-hidden" aria-hidden="true">
            <div className="absolute left-[calc(50%-19rem)] top-[calc(50%-36rem)] transform-gpu blur-3xl">
              <div
                className="aspect-[1097/1023] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-25"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
          </div>
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div className="py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">

          <div className="mx-auto max-w-lg">
            <h2 className="text-2xl font-bold tracking-white text-white sm:text-3xl">
              Have a question?
            </h2>
            <p className="my-6 text-lg leading-8 text-gray-300">
            Fill out the form to request a free quote or ask any questions and we’ll get back to you.</p>
            <dl className="mt-8 text-lg text-gray-300">
              <div>
                <dt className="sr-only">Postal address</dt>
                <dd>

                  <p>45358 Roberts Line </p>
                  <p>Sparta, Ontario</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="h-6 w-6 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <a href="tel:519-902-7506" className="ml-3">519-902-7506</a>

                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <EnvelopeIcon
                    className="h-6 w-6 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <a href="mailto:josh@baileybuildings.com" className="ml-3">josh@baileybuildings.com</a>

                </dd>
              </div>
            </dl>

          </div>
        </div>
        <div className="flex-shrink-0 items-center  sm:py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12 ">
          <div className="mx-auto max-w-lg lg:max-w-none">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}
