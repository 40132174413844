import React from 'react';

export default function Colophon() {
  return (
    // <div className="w-full bg-brand-blue-900 p-6 text-white">
    <div className="border-t border-secondary bg-primary p-6 text-white">
      <div className="mx-auto md:max-w-3xl lg:max-w-7xl">
        <div className="flex flex-col text-center md:flex-row md:justify-between">
          <div className="mt-6 text-sm text-white md:mt-0">
            <p>
              Copyright {new Date().getFullYear()} &copy; Bailey Buildings Inc.{' '}
            </p>
          </div>
          <div>
            <p className='className="mt-6 md:mt-0" text-sm text-white'>
              Designed by{' '}
              <a href="https://baileytech.ca/website-design-st-thomas">
                Bailey Tech
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
