import React from 'react'

import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import { StaticImage } from 'gatsby-plugin-image'

const features = [
  {
    name: 'Push to deploy.',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'SSL certificates.',
    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
    icon: LockClosedIcon,
  },
  {
    name: 'Database backups.',
    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
    icon: ServerIcon,
  },
]

export default function FeatureSectionContentRight() {
  return (
    <div className="overflow-hidden bg-light py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 items-center ">
          <div className="lg:ml-auto lg:pl-4">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Let us grow your vision</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                <span className='highlight'>Bailey Buildings</span> is a family-owned business offering over 25 years of experience. We are a leading and reputable construction company serving St. Thomas and the surrounding area. Our work can be seen around many places in St. Thomas, London/ Middlesex and Elgin County. </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                We specialize in <span className='highlight'>ICF buildings, pole barns, detached structures</span>. We can customize any project and our comprehensive approach to tailored building allows use to provide clients with a wide range of services to meet their needs.
              </p>

            </div>
          </div>
          <div className="flex items-start justify-end lg:order-first">


            <StaticImage
              src="../images/let-us-grow.jpeg"
              alt="Exterior Detached Structure"
              loading='lazy'
              className='aspect-square rounded-3xl shadow-xl ring-1 ring-gray-400/10'
            />

          </div>
        </div>
      </div>
    </div>
  )
}
