import React from 'react';
import classNames from '../utils/classNames';
import ImageGrid from './imageGrid';


export default function Gallery({ buildings }) {
  const { title, icf,custombuilds, polebarns,commercial } = buildings;

  return (
    <div className="py-16">
       <h3 className="pl-5 pb-2 text-2xl font-bold tracking-tight text-gray-900 ">ICF Buildings</h3>
       <ImageGrid images={icf} />
       <h3 className="pl-5 pt-12 pb-2 text-2xl font-bold tracking-tight text-gray-900">Commercial/ Agriculture</h3>
       <ImageGrid images={commercial} />
       <h3 className="pl-5 pt-12 pb-2 text-2xl font-bold tracking-tight text-gray-900">Custom Builds</h3>
       <ImageGrid images={custombuilds} />
       <h3 className="pl-5 pt-12 pb-2 text-2xl font-bold tracking-tight text-gray-900">Pole Barns</h3>
       <ImageGrid images={polebarns} />
    </div>
  );
}
