import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../utils/classNames';

export default function ImageGrid({ images }) {
  const hasMultipleImages = images.length > 1;

  console.log ({images});
  return (
    <div className="flex">
      <div
        className={classNames(
          images.length > 1 ? 'mx-5 grid-cols-2 xl:grid-cols-3' : 'xl:grid-cols-3',
          'col-span-1 grid gap-5'
        )}
      >
        {images.map((image) => (
          <GatsbyImage
            image={image.src.childImageSharp.gatsbyImageData}
            alt={image.title}
            className="h-auto w-auto object-cover aspect-video object-center"
            key={image.title}
            loading='lazy'
          />
        ))}
      </div>
    </div>
  );
}

ImageGrid.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      src: PropTypes.object.isRequired,
    })
  ).isRequired,
};
