import { useState } from 'react';

export default function useQuote({ values }) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  // function run when someone submits quote
  async function submitForm(e) {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setMessage(null);

    const body = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      building: values.building,
      messageText: values.messageText,
      mapleSyrup: values.mapleSyrup,
    };

    const res = await fetch(
      `${process.env.GATSBY_SERVERLESS_BASE}/contactForm`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    );

    const text = JSON.parse(await res.text());


    // check if everthing worked
    if (res.status >= 400 && res.status < 600) {
      setLoading(false); // turn off loading
      setError(text.message);
    } else {
      setLoading(false);
      // setMessage('Success quote request');
      document.location = '/thank-you';
    }
  }

  return { error, loading, message, submitForm };
}
