import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Seo from './seo';

export default function HeroSection() {
  return (
    <div className="bg-white">

      <main>
        <div>
          {/* Hero card */}
          <div className="relative">
            <div className="mx-auto">
              <div className="relative shadow-xl sm:overflow-hidden ">
                <div className="absolute inset-0">
                  <StaticImage
                    src="../images/entrance.jpg"
                    className="h-full w-full object-cover"
                    alt="Bailey Buildings ICF"
                    loading='eager'
                  />

                  <div className="absolute inset-0 bg-gray-100 mix-blend-multiply" />
                </div>
                <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                  <div className="pb-10 text-center">
                    <StaticImage
                      src="../images/bailey-buildings-mark.png"
                      quality={95}
                      loading='eager'
                      formats={['AUTO', 'WEBP']}
                      className="object-fit mx-auto "
                      alt="Bailey Buildings Logo"
                    />
                  </div>
                  <h1 className="text-3xl font-bold tracking-tight sm:text-5xl text-center text-white max-w-5xl mx-auto">
                    <a href="/">We specialize in ICF buildings, pole barns, detached structures.</a>
                  </h1>
                  <div className='mx-auto max-w-7xl flex justify-center py-6'>
                  <Link
                  to="/#contact"
                  className="mt-10 inline-flex justify-center border-2   border-secondary bg-primary py-4 px-4 text-lg font-semibold uppercase text-white  shadow-sm  rounded-3xl hover:bg-secondary hover:text-white  hover:border-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                  >

                Request a Quote
              </Link>
              </div>

                </div>
              </div>
            </div>
          </div>

          {/* Logo cloud */}
        </div>

        {/* More main page content here... */}
      </main>
    </div>
  );
}
